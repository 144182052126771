import React, { useState, useRef, useEffect } from 'react';
import { TbSend } from "react-icons/tb";

const App: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [keywords, setKeywords] = useState<{ word: string, coeff: number }[]>([]);
  const [results, setResults] = useState<{ id: string, name: string, location: string, experience_level: string, score: number, exp_rank: number, weight: number, keyword_rank: number, total_score: number }[]>([]);
  const [feedbackVisible, setFeedbackVisible] = useState(false);
  const [feedbackText, setFeedbackText] = useState('');
  const [showAllResults, setShowAllResults] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [searchQuery]);

  const handleSearch = async () => {
    setLoading(true);
    setKeywords([]);
    setResults([]);
    setShowAllResults(false);

    try {
      const response = await fetch('http://63.250.56.85:8001/query_collections/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text: searchQuery }),
      });

      const data = await response.json();

      const processedResults = data[0].map((result: [string, { name: string, location: string, experience_level: string, score: number, exp_rank: number, weight: number, keyword_rank: number, total_score: number }]) => ({
        id: result[0],
        name: result[1].name,
        location: result[1].location,
        experience_level: result[1].experience_level,
        score: result[1].score,
        exp_rank: result[1].exp_rank,
        weight: result[1].weight,
        keyword_rank: result[1].keyword_rank,
        total_score: result[1].total_score
      }));

      setKeywords(data[1].keywords.map((kw: { word: string, coeff: number }) => ({
        word: kw.word,
        coeff: kw.coeff,
      })));

      setResults(processedResults);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSearch();
    }
  };

  const toggleFeedback = () => {
    setFeedbackVisible(!feedbackVisible);
  };

  const sendFeedback = async () => {
    const sheetUrl = 'https://script.google.com/macros/s/AKfycbyuEtOSSTW6hNtLXLk1t4cnhIxy0kipKuXZ8yTqDFMxnk__aFyZiapBhRyB1iiQE7uy/exec';
    const sendButton = document.getElementById('sendFeedbackButton') as HTMLButtonElement;

    if (sendButton) {
      sendButton.disabled = true;
      sendButton.innerHTML = 'Envoi...';

      try {
        await fetch(sheetUrl, {
          method: 'POST',
          mode: 'no-cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            query: searchQuery,
            results: formattedResults,
            feedback: feedbackText
          })
        });
        alert('Le feedback a bien été envoyé. Merci !');
        setFeedbackText('');
      } catch (error) {
        alert('Erreur lors de l\'envoi du feedback. Veuillez réessayer.');
      }

      sendButton.disabled = false;
      sendButton.innerHTML = 'Send Feedback';
    }
  };

  const getAddress = async (id: string) => {
    try {
      const response = await fetch(`http://63.250.56.85:8001/address/?id=${id}`);
      const data = await response.json();
      const address = data;
      if (address) {
        window.open(address, '_blank');
      }
    } catch (error) {
      console.error('Error fetching address:', error);
    }
  };

  const sortedResults = results.slice().sort((a, b) => b.total_score - a.total_score);

  const formattedResults = sortedResults.map(result => `${result.id}: ${result.total_score.toFixed(3)}`).join(' ------ ');

  return (
    <div className="py-20 flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="mb-8 text-4xl font-bold text-center text-gray-800">TalentMatchers</h1>
      <div className="relative w-full px-16">
        <textarea
          ref={textareaRef}
          placeholder="Recherche..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onKeyDown={handleKeyDown}
          rows={1}
          className="w-full px-4 py-4 pr-16 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
          style={{ resize: 'none', whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
        />
        <button
          onClick={handleSearch}
          className="absolute inset-y-0 right-4 flex items-center px-4 text-white rounded-r-full"
        >
          <TbSend className="w-6 h-6 text-gray-800 hover:text-gray-500" />
        </button>
      </div>
      {loading && <div className="mt-4 text-center"><Spinner /></div>}
      {!loading && keywords.length > 0 && (
        <div className="w-full mt-8 px-16">
          <h2 className="mb-5 text-xl font-bold text-gray-800">Mots clés extraits :</h2>
          <div className="mb-10 text-xl flex flex-wrap">
            {keywords.map((keyword, index) => (
              <span
                key={index}
                className="font-bold mb-5 mr-5"
                style={{ fontSize: `${1 + keyword.coeff * 0.15}rem` }}
              >
                {keyword.word}
              </span>
            ))}
          </div>
          <h2 className="mb-5 text-xl font-bold text-gray-800">Résultats :</h2>
          <div className="feedback-container mt-10 mb-10">
            <button 
              onClick={toggleFeedback} 
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
            >
              Donner un feedback
            </button>

            {feedbackVisible && (
              <div id="feedbackContainer">
                <textarea
                  id="feedbackText"
                  rows={4}
                  cols={50}
                  placeholder="Entrez votre feedback ici..."
                  value={feedbackText}
                  onChange={(e) => setFeedbackText(e.target.value)}
                  className="w-full p-2 mt-2 border rounded"
                /><br />
                <button 
                  id="sendFeedbackButton" 
                  onClick={sendFeedback}
                  className="bg-green-500 text-white py-2 px-4 rounded mt-2 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300"
                >
                  Envoyer le feedback
                </button>
              </div>
            )}
          </div>
          <div className="grid grid-cols-7 gap-4 mb-4 font-bold text-gray-800 text-lg">
            <span>Match combiné</span>
            <span>Match experiences</span>
            <span>Match mots clés</span>
            <span>Nom</span>
            <span>ID</span>
            <span>Localisation</span>
            <span>Niveau d'expérience</span>
          </div>
          <hr className="my-5 border-gray-300" />
          <ul>
            {sortedResults.slice(0, showAllResults ? sortedResults.length : 12).map((result, index) => (
              <li key={index} className="text-gray-700 mb-5">
                <div className="grid grid-cols-7 gap-4 min-w-0 w-full">
                  <span className="truncate font-bold text-lg">{result.total_score.toFixed(1)} %</span>
                  <span className="truncate">{result.score.toFixed(1)} %</span>
                  <span className="truncate">{result.weight.toFixed(1)} %</span>
                  <span 
                    className="truncate text-blue-500 cursor-pointer" 
                    onClick={() => getAddress(result.id)}
                  >
                    {result.name}
                  </span>
                  <span className="truncate">{result.id}</span>
                  <span className="truncate">{result.location}</span>
                  <span className="truncate">{result.experience_level}</span>
                </div>
                <hr className="my-5 border-gray-300" />
              </li>
            ))}
          </ul>
          {sortedResults.length > 12 && (
            <button 
              onClick={() => setShowAllResults(!showAllResults)} 
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 mt-5"
            >
              {showAllResults ? 'Show Less' : 'Show All'}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

const Spinner: React.FC = () => (
  <svg className="w-6 h-6 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.963 7.963 0 014 12H0c0 2.257.88 4.314 2.343 5.657l2.657-2.366z"></path>
  </svg>
);

export default App;
